"use client";

import { TypeHero } from "@contentful/types";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, cn } from "@yardzen/ui";
import { useRouter } from "next/navigation";

export function HeroActions({
  hero,
  className,
  segmentFlow,
  isDesignProfileQuizEnabled,
}: {
  hero: TypeHero<"WITHOUT_UNRESOLVABLE_LINKS">;
  className?: string;
  segmentFlow: string;
  isDesignProfileQuizEnabled: boolean;
}) {
  const router = useRouter();

  return (
    <div
      className={cn(
        "bg-system-grayscale-00 relative inset-0 z-10 -mt-10 flex flex-col items-center gap-4 rounded-2xl bg-opacity-70 p-6 backdrop-blur-lg min-[700px]:-mt-56 md:mx-4 md:mb-20 md:mt-[-20rem] md:w-[430px] md:items-start",
        className,
      )}
    >
      <div className="relative text-center text-white md:text-left">
        <div className="mb-4">
          <h1 className="text-brand-primary-dark font-display mb-1 text-3xl md:text-5xl">
            {hero.fields.title}
          </h1>
        </div>
        <p className="text-brand-primary-dark font-display mb-4 text-lg">
          {hero.fields.subtitle}
        </p>
      </div>
      <div className="relative flex w-full flex-col items-center gap-4 md:w-auto md:flex-row">
        {hero.fields.CTAs?.map((cta) => {
          let props = {};
          if (!cta) {
            return null;
          }
          if (cta.fields.variant === "default") {
            props = {
              endIcon: <FontAwesomeIcon icon={faArrowRight} />,
            };
          }

          const url =
            isDesignProfileQuizEnabled && cta.fields.challengerURL
              ? cta.fields.challengerURL
              : cta.fields.url;

          return (
            <Button
              key={cta.sys.id}
              className="w-full px-4 py-3 md:w-auto"
              variant={cta.fields.variant}
              onClick={async () => {
                url && router.push(url);
              }}
              tracking={{
                button_content: cta.fields.buttonText || "Home page hero CTA",
                flow_name: segmentFlow,
                button_name: cta.fields.buttonText || "Home page hero CTA",
              }}
              {...props}
            >
              {cta.fields.buttonText}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
